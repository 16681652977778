import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { DolPopover } from '@doltech/ui/lib/figma/Popover/DolPopover';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { IconCourseInfoOutline } from '@doltech/icons/IconCourseInfoOutline';

const Main = styled.div`
  border-radius: 8px;
  width: 24px;
  height: 24px;
  color: ${colorsV2.gray140};
  font-size: 16px;
  line-height: 0;
  display: grid;
  place-items: center;
  border: 1px solid ${colorsV2.gray20};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    border-color: ${colorsV2.gray10};
    background-color: ${colorsV2.gray10};
  }
`;

const WarningWrapper = styled.div`
  background-color: ${colorsV2.white100};
  padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid ${colorsV2.neutral40};
`;

export const NotLinkedWarningPopover = () => {
  return (
    <DolPopover
      overlayClassName="un-styled no-arrow custom-inner-content"
      placement="top-end"
      maxWidth="max-content"
      content={
        <WarningWrapper>
          <ResponsiveTypography.Paragraph variant="regular/14-20" color="gray180">
            Bài viết đã bị gỡ khỏi hệ thống
          </ResponsiveTypography.Paragraph>
        </WarningWrapper>
      }
    >
      <Main className="class-detail-info-popover">
        <IconCourseInfoOutline/>
      </Main>
    </DolPopover>
  );
};
